import { SVGAttributes } from "react"
import styled from "styled-components"

interface LogoProps extends SVGAttributes<Element> {
  fill?: string
  size?: number
  withText?: boolean
}

const Logo = ({
  fill = "#000",
  size = 2,
  style,
  withText = true,
  ...props
}: LogoProps) => {
  const widthMultiplier = withText ? 4.375 : 1
  const viewBox = withText ? "0 0 122 22" : "0 0 24 25"

  style = {
    width: size * widthMultiplier + "rem",
    height: size + "rem",
    ...style,
  }

  return (
    <svg
      width={style.width}
      height={style.height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      <title>Adaptive Financial Consulting Logo</title>

      <path
        d="M32.6157 0.184668H35.8898L42.4626 16.9413H39.0437L37.6198 13.1756H30.8092L29.3854 16.9413H26.0402L32.6157 0.184668ZM36.6031 10.5047L34.2063 4.13763L31.8095 10.5047H36.6031Z"
        fill={fill}
      />
      <path
        d="M42.203 10.9008C42.203 7.22881 44.4331 4.69431 48.0406 4.69431C49.5601 4.69431 50.8883 5.32057 51.6016 6.25194V0H54.5915V16.9439H51.6016V15.5255C51.0332 16.4328 49.6803 17.1286 48.0898 17.1286C44.4112 17.1286 42.203 14.5727 42.203 10.9008ZM51.7929 10.9008C51.7929 8.69277 50.4866 7.22881 48.5188 7.22881C46.5511 7.22881 45.242 8.69277 45.242 10.9008C45.242 13.1087 46.5484 14.5968 48.5188 14.5968C50.4893 14.5968 51.7929 13.1328 51.7929 10.9008Z"
        fill={fill}
      />
      <path
        d="M56.2066 13.644C56.2066 11.5297 57.7726 10.2263 60.5029 9.87839L62.9707 9.55187C63.6348 9.4582 63.9683 9.13437 63.9683 8.5991C63.9683 7.7159 63.091 6.97188 61.6425 6.97188C60.2897 6.97188 59.2703 7.66773 59.1746 8.90152H56.2804C56.5647 6.32153 58.5597 4.69431 61.6452 4.69431C64.8975 4.69431 66.9608 6.50619 66.9608 9.36721V13.6199C66.9608 14.2944 67.2232 14.5968 67.7206 14.5968C67.8873 14.5968 68.1251 14.5513 68.1251 14.5513V16.9439C68.1251 16.9439 67.4145 17.0831 66.7012 17.0831C65.4441 17.0831 64.5176 16.5023 64.3755 15.3622C63.8289 16.4301 62.3339 17.1286 60.5056 17.1286C58.0131 17.1286 56.2094 15.6646 56.2094 13.6413M64.0393 11.7384V11.3905L61.0003 11.8776C59.7896 12.0623 59.1965 12.6216 59.1965 13.5048C59.1965 14.3184 59.9071 14.8992 61.0003 14.8992C62.9215 14.8992 64.0393 13.5503 64.0393 11.7384Z"
        fill={fill}
      />
      <path
        d="M69.5681 4.88165H72.5579V6.30012C73.2221 5.34734 74.5995 4.69698 76.2119 4.69698C79.7238 4.69698 81.9785 7.25289 81.9785 10.9034C81.9785 14.554 79.5817 17.1313 75.9987 17.1313C74.5503 17.1313 73.2467 16.5746 72.5579 15.7128V21.2903H69.5681V4.88165ZM78.9203 10.9008C78.9203 8.69277 77.5921 7.22881 75.6681 7.22881C73.7441 7.22881 72.3694 8.69277 72.3694 10.9008C72.3694 13.1087 73.6976 14.5968 75.6681 14.5968C77.6385 14.5968 78.9203 13.1328 78.9203 10.9008Z"
        fill={fill}
      />
      <path
        d="M90.4315 7.32248H87.5592V12.8304C87.5592 13.9705 88.2698 14.5968 89.2673 14.5968C89.7182 14.5968 90.1938 14.5031 90.4315 14.4335V16.968C90.1473 17.0376 89.5761 17.1313 88.912 17.1313C86.3731 17.1313 84.5693 15.8306 84.5693 13.1328V7.32248H82.4813V4.88165H84.5693V1.32479H87.5592V4.88165H90.4315V7.32248Z"
        fill={fill}
      />
      <path
        d="M95.3672 3.23302H92.115V0.165933H95.3672V3.23302ZM92.2353 4.88165H95.2251V16.9439H92.2353V4.88165Z"
        fill={fill}
      />
      <path
        d="M96.3976 4.88165H99.412L102.309 13.5744L105.179 4.88165H108.193L103.708 16.9439H100.907L96.3976 4.88165Z"
        fill={fill}
      />
      <path
        d="M114.011 4.69431C118.045 4.69431 119.991 7.6704 119.991 10.5742C119.991 11.0613 119.945 11.6421 119.945 11.6421H110.951C111.213 13.5717 112.446 14.6637 114.249 14.6637C115.627 14.6637 116.671 14.0588 116.955 12.991H119.923C119.518 15.6165 117.217 17.1286 114.178 17.1286C110.12 17.1286 108.007 14.0615 108.007 10.9008C108.007 7.73999 109.978 4.69431 114.011 4.69431ZM116.764 9.43679C116.646 7.99692 115.695 6.92638 114.011 6.92638C112.467 6.92638 111.423 7.83366 111.046 9.43679H116.766H116.764Z"
        fill={fill}
      />
      <path
        d="M3.55343 1.32479H0.00878143V15.7904H3.55343V1.32479Z"
        fill={fill}
      />
      <path d="M8.79798 4.79601H5.25333V19.2617H8.79798V4.79601Z" fill={fill} />
      <path d="M14.1136 2.48097H10.5689V16.9466H14.1136V2.48097Z" fill={fill} />
      <path d="M19.4319 0.16861H15.8873V14.6343H19.4319V0.16861Z" fill={fill} />
    </svg>
  )
}

export default styled(Logo)`
  [fill] {
    fill: ${(props) =>
      props.theme.newTheme.color["Colors/Text/text-primary (900)"]};
  }
`
