import { SVGAttributes } from "react"

export const CrossIcon = ({
  height = 15,
  width = 15,
  fill,
}: SVGAttributes<Element>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.42488 7.36409L14.2584 1.53032C14.5513 1.23742 14.5513 0.762547 14.2584 0.469659C13.9655 0.176772 13.4906 0.176781 13.1977 0.46968L7.3642 6.30345L1.53032 0.469812C1.23742 0.176925 0.762546 0.176934 0.469659 0.469834C0.176772 0.762733 0.176782 1.23761 0.469681 1.53049L6.30356 7.36414L0.470073 13.1979C0.177186 13.4908 0.177195 13.9656 0.470094 14.2585C0.762994 14.5514 1.23787 14.5514 1.53075 14.2585L7.36424 8.42478L13.1979 14.2582C13.4908 14.551 13.9656 14.551 14.2585 14.2581C14.5514 13.9652 14.5514 13.4904 14.2585 13.1975L8.42488 7.36409Z"
      fill={fill}
    />
  </svg>
)
